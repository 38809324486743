<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" md="3" sm="6">
        <v-card class="pa-3 text-md-center" outlined tile style="height: 300px">
          <v-card-title class="justify-center"></v-card-title>
          <v-card-title class="justify-center"></v-card-title>
          <v-card-text></v-card-text>
          <p class="text-md-center font-weight-bold" style="font-size: x-large">
            진행중인 설문 토픽(문항 수)
          </p>
          <p
            class="text-md-center font-weight-bold"
            style="font-size: x-large; color: #599bff"
          >
            {{ items.campaigns_cnt }} ({{ items.survey_cnt }})
          </p>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" sm="6">
        <v-card class="pa-3 text-md-center" outlined tile style="height: 300px">
          <v-card-title class="justify-center"></v-card-title>
          <v-card-title class="justify-center"></v-card-title>
          <v-card-text></v-card-text>
          <p class="text-md-center font-weight-bold" style="font-size: x-large">
            참여중인 클라이언트
          </p>
          <p
            class="text-md-center font-weight-bold"
            style="font-size: x-large; color: #fe83b7"
          >
            {{ items.clients_cnt }}
          </p>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" sm="6">
        <v-card class="pa-3 text-md-center" outlined tile style="height: 300px">
          <v-card-title class="justify-center"></v-card-title>
          <v-card-title class="justify-center"></v-card-title>
          <v-card-text></v-card-text>
          <p class="text-md-center font-weight-bold" style="font-size: x-large">
            진행중인 언론사
          </p>
          <p
            class="text-md-center font-weight-bold"
            style="font-size: x-large; color: #ffbc57"
          >
            {{ items.medias_cnt }}
          </p>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" sm="6">
        <v-card class="pa-3 text-md-center" outlined tile style="height: 300px">
          <v-card-title class="justify-center"></v-card-title>
          <v-card-title class="justify-center"></v-card-title>
          <v-card-text></v-card-text>
          <p class="text-md-center font-weight-bold" style="font-size: x-large">
            진행중인 카테고리
          </p>
          <p
            class="text-md-center font-weight-bold"
            style="font-size: x-large; color: #26c7aa"
          >
            {{ items.categories_cnt }}
          </p>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "DashBoard",
  data() {
    return {
      items: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.axios.get(`api/v1/manage/dashboard`).then((res) => {
        this.items = res.data.dashboard;
      });
    },
  },
};
</script>
